import { Trans } from "@lingui/macro";
import { Alert, Grid } from "@mui/material";
import { EntryPoint, IApiFact, ExtractedLinkbaseTree } from "../../api/types";
import { useMemo } from "react";
import { filterAnchoringRoleTypes } from "../../helpers/filterAnchoringRoleTypes";
import GeneralSidebarTaxonomyExplorerLazyTreeview from "./generalSidebarTaxonomyExplorerLazyTreeview";

export interface GeneralSidebarAnchoringTreeviewProps {
  definitionTree: ExtractedLinkbaseTree | undefined;
  facts: IApiFact[];
  activeEntryPointHref: string;
  currentSelectedLanguage: string;
  entryPoints: EntryPoint[] | undefined;
  onViewItemDetails: () => void;
  currentWidth: number;
}

const GeneralSidebarAnchoringTreeview = ({
  definitionTree,
  facts,
  activeEntryPointHref,
  currentSelectedLanguage,
  entryPoints,
  onViewItemDetails,
  currentWidth,
}: GeneralSidebarAnchoringTreeviewProps) => {
  const relevantTree = useMemo(
    () => filterAnchoringRoleTypes(definitionTree),
    [definitionTree]
  );

  if (!relevantTree)
    return (
      <Alert variant="outlined" severity="info" sx={{ ml: 2, mt: 2 }}>
        <Trans>Could not find definition linkbase</Trans>
      </Alert>
    );

  return (
    <Grid size={12}>
      <GeneralSidebarTaxonomyExplorerLazyTreeview
        currentSearchFilter=""
        linkbaseTree={relevantTree}
        facts={facts}
        activeEntryPointHref={activeEntryPointHref}
        currentSelectedLanguage={currentSelectedLanguage}
        entryPoints={entryPoints}
        onViewItemDetails={onViewItemDetails}
        currentWidth={currentWidth}
        isPackageTaxonomy={false}
      />
    </Grid>
  );
};

export default GeneralSidebarAnchoringTreeview;
