import { t } from "@lingui/macro";
import { Alert, Container, Grid, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { changePassword } from "../api/accounts";
import { IApiUser } from "../api/types";
import LoadinDialog from "../components/loadingDialog";
import ChangeUserPasswordDialog from "../components/user/changeUserPasswordDialog";
import CreateNewUserDialog from "../components/user/createNewUserDialog";
import CurrentUserDetailsAccordion from "../components/user/currentUserDetailsAccordion";
import DeactivateUserConsentDialog from "../components/user/deactivateUserConsentDialog";
import EditUserNameDialog from "../components/user/editUserNameDialog";
import OrganizationDetailsAccordion from "../components/user/organizationDetailsAccordion";
import OrganizationUserTable from "../components/user/organizationUserTable";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import { useAppSelector } from "../hooks/useAppSelector";
import {
  createUserStarted,
  getCurrentOrgDetails,
  reSendActivationEmailStarted,
  toggleUserActivationStarted,
  updateUserStarted,
  userNameUpdated,
  userSwitchedToEditMode,
} from "../reducers/userManagementReducer";
import tokenHelper from "../helpers/tokenHelper";
import { useNavigate } from "react-router-dom";

export type UserEditingAction =
  | "toggleActivate"
  | "openNameEditDialog"
  | "ResendActivationEmail";

const UserDetails = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.manageUsers.currentUser);
  const navigate = useNavigate();
  const sendEmailPending = useAppSelector(
    (state) => state.manageUsers.sendEmailPending
  );

  const currentOrg = useAppSelector(
    (state) => state.manageUsers.currentOragnization
  );

  const sendEmailError = useAppSelector(
    (state) => state.manageUsers.sendEmailError
  );

  const loadingOrg = useAppSelector((state) => state.manageUsers.getOrgPending);
  const [currentActionUserId, setCurrentActionUserId] = useState("");
  const [currentUserEditingAction, setCurrentUserEditingAction] = useState<
    UserEditingAction | undefined
  >();
  const [showChangeUserPasswordDialog, setShowChangeUserPasswordDialog] =
    useState(false);
  const [showCreateNewUserDialog, setShowCreateNewUserDialog] = useState(false);

  const [savingPass, setSavingPass] = useState(false);
  const [savingNewUser, setSavingNewUser] = useState(false);
  const [savingPassResult, setSavingPassResult] = useState<{
    isSuccess: boolean;
    message: string;
  }>();
  const [savingNewUserResult, setSavingNewUserResult] = useState<{
    isSuccess: boolean;
    message: string;
  }>();

  const [currentOrgUsersTableFilter, setCurrentOrgUsersTableFilter] =
    useState("");

  useEffect(() => {
    if (currentUser) {
      dispatch(
        getCurrentOrgDetails({
          isAdmin: currentUser ? currentUser.isAdmin : false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  const handleCurrentUserNameChanged = async (val: string, userid: string) => {
    await dispatch(
      userNameUpdated({
        id: userid,
        value: val,
      })
    );
  };

  const handleSaveOrgUserName = async (val: string) => {
    const user = {
      ...(currentOrg?.users.find(
        (u) => u.id === currentActionUserId
      ) as IApiUser),
    };
    user.name = val;

    await dispatch(
      updateUserStarted({
        user: user,
      })
    );
    await dispatch(
      getCurrentOrgDetails({
        isAdmin: currentUser ? currentUser.isAdmin : false,
      })
    );
    setCurrentActionUserId("");
    setCurrentUserEditingAction(undefined);
  };

  const handleChangePassword = async (newPass: string, oldPass?: string) => {
    setSavingPass(true);
    setSavingPassResult(undefined);
    try {
      await changePassword(oldPass as string, newPass);
      setSavingPassResult({
        isSuccess: true,
        message: t`Password successfully updated, you will be logged out`,
      });
      tokenHelper.clear();
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (ex: any) {
      setSavingPassResult({
        isSuccess: false,
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Error. Try again later`,
      });
    } finally {
      setSavingPass(false);
      setShowChangeUserPasswordDialog(false);
    }
  };

  const handleSaveNewUser = async (name: string, email: string) => {
    setSavingNewUser(true);
    setSavingNewUserResult(undefined);
    try {
      await dispatch(createUserStarted({ name, email }));
      setSavingNewUserResult({
        isSuccess: true,
        message: t`New user created!`,
      });
    } catch (ex: any) {
      setSavingPassResult({
        isSuccess: false,
        message: ex.response.data.IsTranslated
          ? ex.response.data.Exceptions[0]
          : t`Error. Try again later`,
      });
    } finally {
      setSavingNewUser(false);
      setShowCreateNewUserDialog(false);
    }
  };

  const handleToggleEditUser = (id: string) => {
    const user =
      currentUser?.id === id
        ? currentUser
        : currentOrg?.users.find((u) => u.id === id);
    if (user) {
      if (user.editing) {
        dispatch(updateUserStarted({ user: user }));
      } else {
        dispatch(userSwitchedToEditMode({ id: user.id }));
      }
    }
  };

  const handleOrgUserAction = (
    id: string,
    action: UserEditingAction,
    val?: string | boolean
  ) => {
    setCurrentUserEditingAction(action);
    if (action === "toggleActivate") {
      if (val === false) {
        setCurrentActionUserId(id);
      } else {
        dispatch(
          toggleUserActivationStarted({
            id: id,
            currentlyActivated: val as boolean,
          })
        );
        setCurrentUserEditingAction(undefined);
      }
    } else if (action === "openNameEditDialog") {
      setCurrentActionUserId(id);
    } else if (action === "ResendActivationEmail") {
      handleReSendActivationEmail(id);
    }
  };

  const handleCloseUserDeactivationDialog = (confirmed: boolean) => {
    if (confirmed) {
      dispatch(
        toggleUserActivationStarted({
          id: currentActionUserId,
          currentlyActivated: false,
        })
      );
    }
    setCurrentUserEditingAction(undefined);
    setCurrentActionUserId("");
  };

  const handleReSendActivationEmail = async (id: string) => {
    dispatch(
      reSendActivationEmailStarted({
        id: id,
      })
    );
  };

  useEffect(() => {
    if (savingPassResult) {
      setTimeout(() => {
        setSavingPassResult(undefined);
      }, 3000);
    } else if (savingNewUserResult) {
      setTimeout(() => {
        setSavingNewUserResult(undefined);
      }, 3000);
    }
  }, [savingPassResult, savingNewUserResult]);

  if (loadingOrg) {
    return <LoadinDialog title="Fetching data" />;
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ pt: 15, pb: 10 }}>
        <Grid container spacing={2}>
          {currentUser && (
            <Grid size={12}>
              <CurrentUserDetailsAccordion
                user={currentUser}
                onChangeValue={(val) =>
                  handleCurrentUserNameChanged(val, currentUser.id)
                }
                onToggleSave={() => handleToggleEditUser(currentUser.id)}
                onShowChangePasswordDialog={() =>
                  setShowChangeUserPasswordDialog(true)
                }
              />
            </Grid>
          )}
          {currentOrg && (
            <Grid size={12}>
              <OrganizationDetailsAccordion org={currentOrg} />
            </Grid>
          )}
          {currentUser?.isAdmin &&
            currentOrg?.users &&
            currentOrg.users.length > 0 && (
              <Grid size={12}>
                <OrganizationUserTable
                  orgName={currentOrg.name}
                  users={currentOrg.users.filter((user) =>
                    user.name
                      .toLowerCase()
                      .includes(currentOrgUsersTableFilter.toLowerCase())
                  )}
                  onSignupNewUser={() => setShowCreateNewUserDialog(true)}
                  savingNewUser={savingNewUser}
                  onUserAction={handleOrgUserAction}
                  onChangeFilter={setCurrentOrgUsersTableFilter}
                  nameFilter={currentOrgUsersTableFilter}
                  isLoadingState={sendEmailPending}
                />
              </Grid>
            )}
        </Grid>
      </Container>
      <ChangeUserPasswordDialog
        isSaving={savingPass}
        isOpen={showChangeUserPasswordDialog}
        onCancel={() => setShowChangeUserPasswordDialog(false)}
        onSave={handleChangePassword}
      />
      <CreateNewUserDialog
        isOpen={showCreateNewUserDialog}
        isSaving={savingNewUser}
        onCancel={() => setShowCreateNewUserDialog(false)}
        onSave={handleSaveNewUser}
      />
      <EditUserNameDialog
        isOpen={
          currentActionUserId.length > 0 &&
          currentUserEditingAction === "openNameEditDialog"
        }
        userName={
          currentOrg?.users?.find((u) => u.id === currentActionUserId)?.name ||
          ""
        }
        onCancel={() => {
          setCurrentActionUserId("");
          setCurrentUserEditingAction(undefined);
        }}
        onSave={handleSaveOrgUserName}
      />
      <DeactivateUserConsentDialog
        name={
          currentOrg?.users?.find((u) => u.id === currentActionUserId)?.name ||
          ""
        }
        open={
          currentActionUserId.length > 0 &&
          currentUserEditingAction === "toggleActivate"
        }
        onClose={handleCloseUserDeactivationDialog}
      />
      <Snackbar
        open={
          savingPassResult !== undefined ||
          savingNewUserResult !== undefined ||
          sendEmailError === true
        }
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}>
        <Alert
          severity={
            sendEmailError ||
            savingNewUserResult?.isSuccess === false ||
            savingPassResult?.isSuccess === false
              ? "error"
              : "success"
          }
          sx={{ width: "100%" }}>
          {sendEmailError ||
          savingNewUserResult?.isSuccess === false ||
          savingPassResult?.isSuccess === false
            ? savingPassResult?.message
            : t`Action successful`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserDetails;
