import { useEffect, useState } from "react";
import { IApiOrganizationConversionsQuery, IApiUser } from "../../api/types";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { getCurrentOrgDetails } from "../../reducers/userManagementReducer";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Trans, t } from "@lingui/macro";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface QueryPopOverProps {
  anchorElement: HTMLButtonElement | null;
  queryRequest: IApiOrganizationConversionsQuery;
  onChangeProp: (
    propName: keyof Omit<IApiOrganizationConversionsQuery, "paging">,
    value: string
  ) => void;
  onClose: () => void;
  onClear: () => void;
  onApply: () => void;
}

const QueryPopOver = ({
  anchorElement,
  queryRequest,
  onChangeProp,
  onClose,
  onClear,
  onApply,
}: QueryPopOverProps) => {
  const dispatch = useAppDispatch();
  const loadingOrg = useAppSelector((state) => state.manageUsers.getOrgPending);
  const loadingOrgError = useAppSelector(
    (state) => state.manageUsers.getOrgError
  );
  const orgUsers = useAppSelector(
    (state) => state.manageUsers.currentOragnization?.users
  );

  const [userSelectOpen, setUserSelectOpen] = useState(false);

  useEffect(() => {
    if (
      userSelectOpen &&
      !loadingOrg &&
      !loadingOrgError &&
      (!orgUsers || orgUsers.length === 0)
    ) {
      dispatch(
        getCurrentOrgDetails({
          isAdmin: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectOpen]);

  return (
    <Popover
      open={anchorElement !== null}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}>
      <Container
        sx={{
          zIndex: 5,
          width: 300,
        }}>
        <Grid container spacing={2}>
          <Grid size={7} sx={{ mt: 3 }}>
            <Typography variant="h5" fontWeight={"bold"}>
              Filter
            </Typography>
          </Grid>
          <Grid size={5} sx={{ mt: 3 }}>
            <Button variant="text" onClick={onClear}>
              <Trans>Clear all</Trans>
            </Button>
          </Grid>
          <Grid size={12}>
            <Autocomplete
              id="id"
              open={userSelectOpen}
              onOpen={() => {
                setUserSelectOpen(true);
              }}
              onClose={() => {
                setUserSelectOpen(false);
              }}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option.name}
              options={orgUsers || []}
              value={
                orgUsers?.find((u) => u.id === queryRequest.userId) || null
              }
              onChange={(_, newValue: IApiUser | null) =>
                onChangeProp("userId", newValue?.id || "")
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t`Select User`}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingOrg ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                label={t`Imported After`}
                value={
                  queryRequest.convertStartAt
                    ? moment(queryRequest.convertStartAt)
                    : null
                }
                disableFuture
                onChange={(newValue) => {
                  onChangeProp(
                    "convertStartAt",
                    newValue?.isValid() ? newValue.toISOString() : ""
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                format="DD/MM/YYYY"
                label={t`Imported Before`}
                value={
                  queryRequest.convertEndAt
                    ? moment(queryRequest.convertEndAt)
                    : null
                }
                disableFuture
                onChange={(newValue) => {
                  onChangeProp(
                    "convertEndAt",
                    newValue?.isValid() ? newValue.toISOString() : ""
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={12} sx={{ mb: 3 }}>
            <Button
              disabled={
                queryRequest.convertEndAt === "" &&
                queryRequest.convertStartAt === "" &&
                queryRequest.userId === ""
              }
              onClick={onApply}
              variant="contained"
              sx={{ float: "right" }}>
              <Trans>Apply</Trans>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Popover>
  );
};

export default QueryPopOver;
