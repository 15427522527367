import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Protected from "./protected";
import Navbar from "./navbar";
import Login from "../pages/login";
import Logout from "../pages/logout";
import MicrosoftLoginCallback from "../pages/microsoftLoginCallback";
import ForgotPassword from "../pages/forgotPassword";
import ResetPassword from "../pages/resetPassword";
import Importer from "../pages/importer";
import UploadIcon from "@mui/icons-material/Upload";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneIcon from "@mui/icons-material/Done";
import Facts from "../pages/facts";
import {
  organizationHasConvertor,
  organizationHasInspector,
} from "../helpers/tokenDecoder";
import tokenHelper from "../helpers/tokenHelper";
import Extensions from "../pages/extensions";
import Viewer from "../pages/viewer";
import { t } from "@lingui/macro";
import GeneralSidebarResizableDrawer from "../components/sidebar/generalSidebarResizableDrawer";
import { minimumGeneralSidebarDrawerWidth } from "../helpers/constants";
import { useAppSelector } from "../hooks/useAppSelector";
import { DrawerIcon } from "../components/sidebar/generalSidebarResizableDrawerBodyClosed";
import { SummaryComponent } from "../pages/summary";
import UserDetails from "../pages/userDetails";
import { useAppDispatch } from "../hooks/useAppDisplatch";
import tokenStorageHelper from "../helpers/tokenHelper";
import DifferenceIcon from "@mui/icons-material/Difference";
import Compare from "../pages/compare";
import WithData from "./withData";
import { getCurrentUserDetails } from "../reducers/userManagementReducer";
import { GetImportStatus } from "../helpers/getImportStatus";
import {
  factSelectionCallerUpdated,
  selectedFactIdsUpdated,
} from "../reducers/internalLifeCycleReducer";
import ConversionHistory from "../pages/conversionHistory";
import BugReportIcon from "@mui/icons-material/BugReport";
import Debug from "../pages/debug";
import Signup from "../pages/signup";
import { IApiFact } from "../api/types";
import UserIsParseportMember from "./UserIsParseportMember";

export type FactSelectionCaller =
  | "viewer"
  | "facts"
  | "extensions"
  | "sidebar"
  | "summary_viewer"
  | "summary_facts"
  | "summary_presentation"
  | "";

const MainNavigation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentContentLanguage, setCurrentContentLanguage] = useState("en");
  const [userSelectedLang, setUserSelectedLang] = useState(false);
  const [generalSidebarActiveSectionId, setGeneralSidebarActiveSectionId] =
    useState<DrawerIcon["id"]>();

  const [isSecondarySidebarOpen, setIsSecondarySidebarOpen] =
    useState<boolean>(false);

  const handleToggleSecondarySidebar = () => {
    setIsSecondarySidebarOpen((prev) => !prev);
  };

  const importProcessFinished = useAppSelector(
    (state) => state.import.downloadedPayloadUrl !== ""
  );

  const downloadedPayloadType = useAppSelector(
    (state) => state.import.downloadedPayloadType
  );

  const validationDataLoaded = useAppSelector(
    (state) => state.validate.validationResult !== null
  );

  const extractionDataLoaded = useAppSelector(
    (state) =>
      state.extract.extractedBasicData !== null &&
      JSON.stringify(state.extract.extractedBasicData) !== "{}" &&
      state.extract.facts.length > 0 &&
      (!state.extract.factsExtractionError ||
        !state.extract.basicDataExtractionError)
  );

  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );
  const factsSelectionCaller = useAppSelector(
    (state) => state.internalLifeCycle.factSelectionCaller
  );

  const extractedFactsType = useAppSelector((state) => state.extract.factType);

  const handleUserChangedLanguage = (selectedLang: string): void => {
    setCurrentContentLanguage(selectedLang);
    if (!userSelectedLang) {
      setUserSelectedLang(true);
    }
  };

  useEffect(() => {
    const handleInvalidToken = () => {
      if (tokenStorageHelper.isInvalid) {
        if (
          localStorage.getItem("code_challenge") === undefined ||
          localStorage.getItem("state") === undefined
        ) {
          navigate("/logout");
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return () => {
      window.removeEventListener("storage", handleInvalidToken);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUser = useAppSelector((state) => state.manageUsers.currentUser);

  useEffect(() => {
    if (!currentUser && tokenStorageHelper.isValid) {
      dispatch(getCurrentUserDetails());
    }
  }, [dispatch, currentUser]);

  const generalSidebarActiveSectionIdRef = useRef(
    generalSidebarActiveSectionId
  );

  const handleChangeGeneralSidebarActiveSectionId = (
    input?: DrawerIcon["id"],
    fromViewer?: boolean
  ) => {
    dispatch(factSelectionCallerUpdated(""));
    if (input) {
      setGeneralSidebarActiveSectionId(input);
      generalSidebarActiveSectionIdRef.current = input;
    } else {
      if (!generalSidebarActiveSectionIdRef.current) {
        setGeneralSidebarActiveSectionId(
          fromViewer ? "factDetails" : "explorer"
        );
        generalSidebarActiveSectionIdRef.current = fromViewer
          ? "factDetails"
          : "explorer";
      } else {
        setGeneralSidebarActiveSectionId(undefined);
        generalSidebarActiveSectionIdRef.current = undefined;
      }
    }
  };

  const [currentSidebarDrawerWidth, setCurrentSidebarDrawerWidth] = useState(
    minimumGeneralSidebarDrawerWidth
  );

  const isGeneralSidebarOpen = useMemo(
    () =>
      extractedFactsType === "standard" &&
      (generalSidebarActiveSectionId !== undefined ||
        factsSelectionCaller !== ""),
    [extractedFactsType, generalSidebarActiveSectionId, factsSelectionCaller]
  );

  useEffect(() => {
    if (isSecondarySidebarOpen) {
      setCurrentSidebarDrawerWidth(currentSidebarDrawerWidth + 350);
    } else if (currentSidebarDrawerWidth > minimumGeneralSidebarDrawerWidth) {
      setCurrentSidebarDrawerWidth(currentSidebarDrawerWidth - 350);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSecondarySidebarOpen]);

  const handleResizeSidebarDrawer = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (generalSidebarActiveSectionIdRef.current) {
        const minDrawerWidth = isSecondarySidebarOpen
          ? minimumGeneralSidebarDrawerWidth + 350
          : minimumGeneralSidebarDrawerWidth;
        const maxDrawerWidth = 1500;
        const newWidth =
          document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        if (newWidth < minDrawerWidth) {
          setCurrentSidebarDrawerWidth(minDrawerWidth);
        } else if (newWidth < maxDrawerWidth) {
          setCurrentSidebarDrawerWidth(newWidth);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isSecondarySidebarOpen]
  );

  const relevantLanguages = useAppSelector(
    (state) =>
      state.extract.extractedBasicData?.taxonomyLanguages ||
      state.extract.extractedBasicData?.baseTaxonomyLanguages
  );

  const reportDefaultLanguage = useAppSelector(
    (state) => state.extract.extractedBasicData?.reportDefaultLanguage
  );

  const factsDefaultLanguage = useAppSelector((state) =>
    state.extract.factType === "standard"
      ? (state.extract.facts as IApiFact[]).find(
        (fact) => fact.factElement?.label?.lang
      )?.factElement.label?.lang
      : state.extract.extractedBasicData?.reportDefaultLanguage
  );

  const validationError = useAppSelector(
    (state) => state.validate.validationError
  );

  const conversionFailed = useAppSelector(
    (state) => state.convert.conversionFailed
  );

  const factsExtractionError = useAppSelector(
    (state) => state.extract.factsExtractionError
  );

  const basicDataExtractionError = useAppSelector(
    (state) => state.extract.basicDataExtractionError
  );

  const generateError = useAppSelector((state) => state.generate.generateError);

  const downloadConvertedPayloadError = useAppSelector(
    (state) => state.convert.downloadConvertedPayloadError
  );

  const conversionStatus = GetImportStatus(
    conversionFailed,
    validationError,
    factsExtractionError,
    basicDataExtractionError,
    generateError,
    downloadConvertedPayloadError
  );

  const allTabs = useMemo(() => {
    const tabs = [{ path: "/import", label: t`Import`, icon: UploadIcon }];
    if (organizationHasInspector(tokenHelper.token)) {
      if (importProcessFinished) {
        tabs.push(
          ...[
            {
              path: "/summary",
              label: t`Summary`,
              icon: DoneIcon,
            },
            {
              path: "/viewer",
              label: t`Viewer`,
              icon: VisibilityIcon,
            },
          ]
        );
      }
      if (extractionDataLoaded && conversionStatus === "success") {
        tabs.push({
          path: "/facts",
          label: t`Facts`,
          icon: FactCheckIcon,
        });
      }
      if (
        extractionDataLoaded &&
        conversionStatus === "success" &&
        downloadedPayloadType === "package"
      ) {
        tabs.push({
          path: "/extensions",
          label: t`Extensions`,
          icon: FactCheckIcon,
        });
      }
      if (
        extractionDataLoaded &&
        conversionStatus === "success" &&
        extractedFactsType === "standard"
      ) {
        tabs.push({
          path: "/compare",
          label: t`Compare`,
          icon: DifferenceIcon,
        });
      }
    } else if (
      organizationHasConvertor(tokenHelper.token) &&
      validationDataLoaded
    ) {
      tabs.push({
        path: "/summary",
        label: t`Summary`,
        icon: DoneIcon,
      });
    }
    if (currentUser?.organizationId === process.env.REACT_APP_ParseportOrgId) {
      tabs.push({
        path: "/debug",
        label: t`Debug`,
        icon: BugReportIcon,
      });
    }
    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tokenHelper.token,
    importProcessFinished,
    validationDataLoaded,
    extractionDataLoaded,
    extractedFactsType,
    currentUser,
  ]);

  useEffect(() => {
    if (!userSelectedLang) {
      if (reportDefaultLanguage && reportDefaultLanguage !== "") {
        setCurrentContentLanguage(reportDefaultLanguage);
      } else if (factsDefaultLanguage) {
        setCurrentContentLanguage(factsDefaultLanguage);
      } else if (relevantLanguages && relevantLanguages.length > 0) {
        setCurrentContentLanguage(relevantLanguages[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDefaultLanguage, relevantLanguages, factsDefaultLanguage]);

  useEffect(() => {
    if (
      selectedFactIds.length > 0 &&
      generalSidebarActiveSectionId === undefined
    ) {
      if (factsSelectionCaller === "summary_viewer") {
        setGeneralSidebarActiveSectionId("factDetails");
        generalSidebarActiveSectionIdRef.current = "factDetails";
      } else if (factsSelectionCaller !== "") {
        setGeneralSidebarActiveSectionId("explorer");
        generalSidebarActiveSectionIdRef.current = "explorer";
      }
    }
  }, [selectedFactIds, factsSelectionCaller, generalSidebarActiveSectionId]);

  const location = useLocation();
  useEffect(() => {
    if (!location.state || !(location.state as any).keepSelectedIds) {
      dispatch(selectedFactIdsUpdated([]));
      dispatch(factSelectionCallerUpdated(""));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <>
      {tokenHelper.token && <Navbar tabs={allTabs} />}
      <Routes>
        <Route
          path="/import"
          element={
            <Protected>
              <Importer />
            </Protected>
          }
        />
        <Route
          path="/summary"
          element={
            <Protected>
              <WithData>
                <SummaryComponent />
              </WithData>
            </Protected>
          }
        />
        <Route
          path="/viewer"
          element={
            <Protected>
              <WithData>
                <Viewer
                  isGeneralSidebarOpen={isGeneralSidebarOpen}
                  currentGeneralSidebarDrawerWidth={currentSidebarDrawerWidth}
                  onToggleSidebar={() => {
                    handleChangeGeneralSidebarActiveSectionId(undefined, true);
                  }}
                  allLanguages={relevantLanguages}
                  currentSelectedLanguage={currentContentLanguage}
                  onChangeLang={handleUserChangedLanguage}
                />
              </WithData>
            </Protected>
          }
        />
        <Route
          path="/facts"
          element={
            <Protected>
              <WithData>
                <Facts
                  isGeneralSidebarOpen={isGeneralSidebarOpen}
                  currentGeneralSidebarDrawerWidth={currentSidebarDrawerWidth}
                  allLanguages={relevantLanguages || []}
                  currentSelectedLanguage={currentContentLanguage}
                  onChangeLang={handleUserChangedLanguage}
                />
              </WithData>
            </Protected>
          }
        />
        <Route
          path="/extensions"
          element={
            <Protected>
              <WithData>
                <Extensions
                  isGeneralSidebarOpen={isGeneralSidebarOpen}
                  currentGeneralSidebarDrawerWidth={currentSidebarDrawerWidth}
                  allLanguages={relevantLanguages || []}
                  currentSelectedLanguage={currentContentLanguage}
                  onChangeLang={handleUserChangedLanguage}
                />
              </WithData>
            </Protected>
          }
        />
        <Route
          path="/compare"
          element={
            <Protected>
              <WithData>
                <Compare currentSelectedLanguage={currentContentLanguage} />
              </WithData>
            </Protected>
          }
        />
        <Route
          path="/user"
          element={
            <Protected>
              <UserDetails />
            </Protected>
          }
        />
        <Route
          path="/conversionhistory"
          element={
            <Protected>
              <ConversionHistory />
            </Protected>
          }
        />
        <Route
          path="/debug"
          element={
            <Protected>
              <UserIsParseportMember>
                <Debug />
              </UserIsParseportMember>
            </Protected>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/accounts/signup" element={<Signup />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/accounts/signup/finalize" element={<ResetPassword />} />
        <Route path="/accounts/password/forgot" element={<ForgotPassword />} />
        <Route path="/accounts/password/reset" element={<ResetPassword />} />
        <Route
          path="/accounts/login/microsoft/callback"
          element={<MicrosoftLoginCallback />}
        />
        <Route path="/" element={<Navigate to="/import" replace={true} />} />
        <Route path="*" element={<Navigate to="/import" replace={true} />} />
      </Routes>
      <GeneralSidebarResizableDrawer
        isOpen={isGeneralSidebarOpen}
        secondarySidebarSize={350}
        isSecondarySidebarOpen={isSecondarySidebarOpen}
        onToggleSecondarySidebar={handleToggleSecondarySidebar}
        currentSidebarId={generalSidebarActiveSectionIdRef.current}
        onToggleOpen={handleChangeGeneralSidebarActiveSectionId}
        currentWidth={currentSidebarDrawerWidth}
        currentSelectedLanguage={currentContentLanguage}
        onResizeDrawer={handleResizeSidebarDrawer}
        activeSectionId={generalSidebarActiveSectionId}
        hidden={!extractionDataLoaded || extractedFactsType === "eurofiling"}
      />
    </>
  );
};

export default MainNavigation;
