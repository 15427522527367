import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ChildFileProps } from "../../../../api/types";

const ChildFile = (props: ChildFileProps) => {
  return (
    <Grid container>
      <Grid size={5}>
        <Typography
          sx={{ maxWidth: 500 }}
          noWrap
          title={props.fileName}
          data-downloadaspdf="zipcontentrowfilename">
          {props.fileName}
        </Typography>
      </Grid>
      <Grid size={2}>
        <Typography
          sx={{ maxWidth: 200 }}
          variant="body2"
          noWrap
          title={props.fileKind}
          data-downloadaspdf="zipcontentrowfiletype">
          {props.fileKind}
        </Typography>
      </Grid>
      <Grid size={4}>
        <Typography
          sx={{ maxWidth: 400 }}
          variant="body2"
          noWrap
          title={props.fileHashCode}
          data-downloadaspdf="zipcontentrowfilehash">
          {props.fileHashCode}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ChildFile;
