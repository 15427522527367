import { t, Trans } from "@lingui/macro";
import {
  Typography,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Alert,
  AlertTitle,
  Paper,
  Grid,
  TableSortLabel,
  Box,
} from "@mui/material";
import { IApiUser } from "../../api/types";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import OrganizationUserTableRow from "./organizationUserTableRow";
import { useState } from "react";
import { getComparator, Order } from "../../helpers/tableSorting";
import { UserEditingAction } from "../../pages/userDetails";

export interface OrganizationUserTableProps {
  orgName: string;
  users: IApiUser[];
  onUserAction: (
    userId: string,
    action: UserEditingAction,
    val?: string | boolean
  ) => void;
  onSignupNewUser: () => void;
  savingNewUser: boolean;
  nameFilter: string;
  onChangeFilter: (val: string) => void;
  isLoadingState: {
    id: string;
    pending: boolean;
  };
}

const OrganizationUserTable = ({
  orgName,
  users,
  onUserAction,
  onSignupNewUser,
  savingNewUser,
  nameFilter,
  onChangeFilter,
  isLoadingState,
}: OrganizationUserTableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IApiUser>("name");

  const handleSortTable = (
    event: React.MouseEvent<unknown>,
    property: keyof IApiUser
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Paper variant="outlined">
      <Grid container spacing={0}>
        <Grid
          size={10}
          sx={{
            pl: (theme) => theme.spacing(3),
            pr: (theme) => theme.spacing(3),
            pt: (theme) => theme.spacing(3),
            pb: 0,
          }}>
          <Typography>
            {orgName} <Trans>Users</Trans>
          </Typography>
        </Grid>

        <Grid
          size={2}
          sx={{
            pl: (theme) => theme.spacing(3),
            pr: (theme) => theme.spacing(3),
            pt: (theme) => theme.spacing(3),
            pb: 0,
          }}>
          <IconButton
            disabled={savingNewUser}
            onClick={onSignupNewUser}
            aria-label="add new user"
            sx={{ float: "right", paddingBottom: 0 }}>
            <AddCircleOutlinedIcon sx={{ fontSize: 50 }} />
          </IconButton>
        </Grid>
        <Grid size={12} sx={{ pl: (theme) => theme.spacing(3) }}>
          {/* added form tag to prevent browser from auto-filling on change password click */}
          <form autoComplete="do-not-autofill">
            <TextField
              value={nameFilter}
              placeholder={t`Search user by name`}
              onChange={(e) => onChangeFilter(e.currentTarget.value)}
            />
          </form>
        </Grid>
        <Grid size={12}>
          <Box sx={{ pl: 3, pr: 3 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>Name</Trans>
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={(e) =>
                        handleSortTable(e, "name")
                      }></TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Trans>Email Address</Trans>
                    <TableSortLabel
                      active={orderBy === "emailAddress"}
                      direction={orderBy === "emailAddress" ? order : "asc"}
                      onClick={(e) =>
                        handleSortTable(e, "emailAddress")
                      }></TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Trans>Identifier</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Is Administrator</Trans>
                    <TableSortLabel
                      active={orderBy === "isAdmin"}
                      direction={orderBy === "isAdmin" ? order : "asc"}
                      onClick={(e) =>
                        handleSortTable(e, "isAdmin")
                      }></TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Trans>Activated</Trans>
                    <TableSortLabel
                      active={orderBy === "isDeactiviated"}
                      direction={orderBy === "isDeactiviated" ? order : "asc"}
                      onClick={(e) =>
                        handleSortTable(e, "isDeactiviated")
                      }></TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Trans>Resend activation email</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .map((user, index) => (
                    <OrganizationUserTableRow
                      onUserAction={onUserAction}
                      rowIndex={index}
                      user={user}
                      isLoadingState={isLoadingState}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>
          {users.length === 0 && (
            <Alert severity="info">
              <AlertTitle>
                <Trans>No data found</Trans>
              </AlertTitle>
              <Trans>Consider removing any filtering text</Trans>
            </Alert>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrganizationUserTable;
