import { useState, useEffect } from "react";

/// This function is used to debounce a value. It will return the value after the specified delay.
export function useDebouncedValue(value: string | undefined, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}