import { Trans } from "@lingui/macro";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ButtonGroup, Grid, IconButton, Typography } from "@mui/material";
import { IApiFact } from "../../api/types";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import {
  factSelectionCallerUpdated,
  selectedFactIdsUpdated,
} from "../../reducers/internalLifeCycleReducer";

interface GeneralSidebarDuplicateFactSwitcherProps {
  allFacts: IApiFact[];
  currentFact: IApiFact | undefined;
}

const GeneralSidebarDuplicateFactSwitcher = ({
  allFacts,
  currentFact,
}: GeneralSidebarDuplicateFactSwitcherProps) => {
  const dispatch = useAppDispatch();
  if (!currentFact) return <></>;
  const duplicateFacts = allFacts.filter(
    (fact) =>
      fact.contextRef?.context?.id === currentFact.contextRef?.context?.id &&
      fact.factElement?.namespace?.uri ===
      currentFact.factElement?.namespace?.uri &&
      fact.factElement?.name === currentFact.factElement?.name
  );
  if (duplicateFacts.length < 2) return <></>;
  const currentFactIndex = duplicateFacts.findIndex(
    (f) => f.id === currentFact.id
  );

  const handleSwitch = (isForward: boolean) => {
    const newId =
      duplicateFacts[isForward ? currentFactIndex + 1 : currentFactIndex - 1]
        .id;
    dispatch(selectedFactIdsUpdated(newId));
    dispatch(factSelectionCallerUpdated("sidebar"));
  };
  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid size={6}>
        <ButtonGroup variant="text">
          <IconButton
            onClick={() => handleSwitch(false)}
            disabled={currentFactIndex === 0}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="body2" sx={{ lineHeight: 2.7 }}>
            {currentFactIndex + 1} <Trans>of</Trans> {duplicateFacts.length}
          </Typography>
          <IconButton
            onClick={() => handleSwitch(true)}
            disabled={currentFactIndex === duplicateFacts.length - 1}>
            <ArrowForwardIosIcon />
          </IconButton>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default GeneralSidebarDuplicateFactSwitcher;
