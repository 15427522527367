/* Mui Accordion */
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Box,
  Container,
  FormHelperText,
  Grid,
  LinearProgress,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useEffect, useMemo } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
/* Translation of Text */
import { t, Trans } from "@lingui/macro";
import { GeneralInformationProps, IApiFact } from "../../api/types";

import {
  gleifPublicUrl,
  gleifUrl,
  identifierFactName,
  nablNL,
} from "../../helpers/constants";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { getEdgeDateFromFacts } from "../../helpers/getEdgeDateFromFacts";
import { getGleif } from "../../reducers/auxDataReducer";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { isReportingEntityNameMatch } from "../../helpers/isReportingEntityNameMatch";
import { useNavigate } from "react-router-dom";
import { selectedFactIdsUpdated } from "../../reducers/internalLifeCycleReducer";

export const GeneralInformation = (props: GeneralInformationProps) => {
  const dispatch = useAppDispatch();

  const GleifData = useAppSelector((state) => state.auxData.gleifData);
  const extractedFactsType = useAppSelector((state) => state.extract.factType);
  const facts = useAppSelector((state) => state.extract.facts);
  const GleifDataLoaded = useAppSelector(
    (state) => state.auxData.gleifDataIsLoaded
  );

  const downloadedPayloadType = useAppSelector(
    (state) => state.import.downloadedPayloadType
  );
  const uploadedFileNames = useAppSelector(
    (state) => state.import.uploadedFileNames
  );

  useEffect(() => {
    if (extractedFactsType === "standard" && facts.length > 0) {
      const idendifierFact = (facts as IApiFact[]).find(
        (f) => f.contextRef?.context?.entity?.identifierValue
      );
      if (idendifierFact) {
        dispatch(
          getGleif({
            Id: idendifierFact.contextRef.context.entity.identifierValue,
          })
        );
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facts, extractedFactsType]);

  const GleifValidNameMemo = useMemo(() => {
    if (extractedFactsType === "standard") {
      return isReportingEntityNameMatch(facts as IApiFact[], GleifData);
    }
  }, [GleifData, facts, extractedFactsType]);

  const nameFact =
    extractedFactsType === "standard"
      ? (facts as IApiFact[]).find((fact) => {
          try {
            return fact.contextRef.name === identifierFactName;
          } catch (error) {
            console.error("Error occurred while finding name fact:", error);
            return undefined;
          }
        })
      : undefined;

  const navigate = useNavigate();

  const HandleNavigateToNameFact = () => {
    if (!nameFact) return;
    dispatch(selectedFactIdsUpdated([nameFact.id]));
    navigate("/facts?filter=name");
  };

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography
            data-downloadaspdf="generalinformationheader"
            variant="h6">
            <Trans> General Information </Trans>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            minHeight: GleifValidNameMemo ? 315 : 370,
            maxHeight: GleifValidNameMemo ? 315 : 370,
            pl: 1,
            pr: 1,
          }}>
          {((facts && facts.length > 0) ||
            downloadedPayloadType === "viewerFile") && (
            <Grid size={12}>
              <Stack
                spacing={0.8}
                divider={<Divider orientation="horizontal" flexItem />}>
                {(props.Name || downloadedPayloadType === "viewerFile") && (
                  <Box
                    maxWidth={"xl"}
                    sx={{
                      display: "flex",
                      pr: 10,
                      pl: 2,
                    }}>
                    <Typography
                      data-downloadaspdf="generalinformationkey"
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        maxWidth: "300px",
                        display: "flex",
                        flex: 1,
                        flexBasis: "auto",
                      }}>
                      <Trans> Name: </Trans>
                    </Typography>
                    <StyledTypography
                      variant="body2"
                      noWrap
                      title={props.Name || uploadedFileNames[0] || "N/A"}
                      data-downloadaspdf="generalinformationvalue">
                      {props.Name || uploadedFileNames[0] || "N/A"}
                    </StyledTypography>
                  </Box>
                )}
                {props.Id && (
                  <Box
                    maxWidth={"xl"}
                    sx={{
                      display: "flex",
                      pr: 10,
                      pl: 2,
                    }}>
                    <Typography
                      data-downloadaspdf="generalinformationkey"
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        maxWidth: "300px",
                        display: "flex",
                        flex: 1,
                        flexBasis: "auto",
                      }}>
                      <Trans> Identifier: </Trans>
                    </Typography>
                    <StyledTypography
                      variant="body2"
                      noWrap
                      title={props.Id}
                      data-downloadaspdf="generalinformationvalue">
                      {GleifData && extractedFactsType === "standard" && (
                        <a
                          href={
                            gleifUrl +
                            ((facts as IApiFact[]).find(
                              (item) =>
                                item?.contextRef?.context?.entity
                                  ?.identifierValue
                            )?.contextRef?.context?.entity?.identifierValue ||
                              "")
                          }
                          target="_blank"
                          rel="noreferrer">
                          {(facts as IApiFact[]).find(
                            (item) =>
                              item?.contextRef?.context?.entity?.identifierValue
                          )?.contextRef?.context?.entity?.identifierValue || ""}
                        </a>
                      )}
                      {!GleifData && (
                        <>
                          {(facts as IApiFact[]).find(
                            (item) =>
                              item?.contextRef?.context?.entity?.identifierValue
                          )?.contextRef?.context?.entity?.identifierValue || ""}
                        </>
                      )}
                    </StyledTypography>
                  </Box>
                )}
                {facts && facts.length > 0 && (
                  <Box
                    maxWidth={"xl"}
                    sx={{
                      display: "flex",
                      pr: 10,
                      pl: 2,
                    }}>
                    <Typography
                      data-downloadaspdf="generalinformationkey"
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        maxWidth: "300px",
                        display: "flex",
                        flex: 1,
                        flexBasis: "auto",
                      }}>
                      <Trans> Reporter Period: </Trans>
                    </Typography>
                    <Typography
                      variant="body2"
                      noWrap
                      title={
                        getEdgeDateFromFacts(facts, extractedFactsType, true) +
                        " - " +
                        getEdgeDateFromFacts(facts, extractedFactsType, false)
                      }
                      data-downloadaspdf="generalinformationvalue">
                      {getEdgeDateFromFacts(facts, extractedFactsType, true)} -{" "}
                      {getEdgeDateFromFacts(facts, extractedFactsType, false)}
                    </Typography>
                  </Box>
                )}

                <Box
                  maxWidth={"xl"}
                  sx={{
                    display: "flex",
                    pr: 10,
                    pl: 2,
                  }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      maxWidth: "300px",
                      display: "flex",
                      flex: 1,
                      flexBasis: "auto",
                    }}
                    data-downloadaspdf="generalinformationkey"
                    variant="body2">
                    <Trans> Hashcode: </Trans>
                    <a
                      href={nablNL}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "black", paddingBottom: 0 }}>
                      <LaunchIcon fontSize={"small"} />
                    </a>
                  </Typography>
                  <StyledTypography
                    data-downloadaspdf="generalinformationvalue"
                    variant="body2">
                    {props && props.hashCode
                      ? props.hashCode.substring(props.hashCode.length - 16)
                      : "N/A"}
                  </StyledTypography>
                </Box>
              </Stack>
              {GleifData !== null && (
                <Box
                  sx={{
                    pl: 2,
                    pb: 2,
                    pt: 2,
                    background: (theme) => theme.palette.grey[300],
                  }}>
                  <Stack
                    spacing={1}
                    divider={<Divider orientation="horizontal" flexItem />}>
                    <Box
                      maxWidth={"xl"}
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        pr: 10,
                      }}>
                      <Typography
                        data-downloadaspdf="generalinformationkey"
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          maxWidth: "300px",
                          display: "flex",
                          flex: 1,
                          flexBasis: "auto",
                        }}>
                        <Trans> Legal Name: </Trans>
                      </Typography>
                      <Typography
                        data-downloadaspdf="generalinformationvalue"
                        variant="body2"
                        sx={{
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor:
                            nameFact !== undefined ? "pointer" : "default",
                        }}
                        onClick={() => {
                          HandleNavigateToNameFact();
                        }}
                        title={
                          nameFact !== undefined
                            ? "Go to fact with Legal name"
                            : GleifData?.data[0].attributes.entity.legalName
                                .name
                        }>
                        <span style={{ borderBottom: "1px solid black" }}>
                          {GleifData?.data[0].attributes.entity.legalName.name}
                        </span>
                      </Typography>
                    </Box>
                    {GleifData?.data[0].attributes.entity.otherNames ===
                      undefined ||
                    GleifData?.data[0].attributes.entity.otherNames === null ||
                    GleifData?.data[0].attributes.entity.otherNames.length >
                      1 ? (
                      <Box
                        maxWidth={"xl"}
                        sx={{
                          display: "flex",
                          pr: 10,
                        }}>
                        <Typography
                          data-downloadaspdf="generalinformationkey"
                          variant="body2"
                          sx={{
                            fontWeight: "bold",
                            maxWidth: "300px",
                            display: "flex",
                            flex: 1,
                            flexBasis: "auto",
                          }}>
                          <Trans> Other Names </Trans>:
                        </Typography>
                        <StyledTypography
                          data-downloadaspdf="generalinformationvalue"
                          variant="body2">
                          {GleifData?.data[0].attributes.entity.otherNames.map(
                            (item) => {
                              return item.name + ", ";
                            }
                          )}
                        </StyledTypography>
                      </Box>
                    ) : null}
                    <Box
                      maxWidth={"xl"}
                      sx={{
                        display: "flex",
                        pr: 10,
                      }}>
                      <Typography
                        data-downloadaspdf="generalinformationkey"
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          maxWidth: "300px",
                          display: "flex",
                          flex: 1,
                          flexBasis: "auto",
                        }}>
                        <Trans> Registered As </Trans>:
                      </Typography>
                      {extractedFactsType === "standard" && (
                        <StyledTypography
                          data-downloadaspdf="generalinformationvalue"
                          variant="body2"
                          title={
                            (facts as IApiFact[])[0]?.contextRef?.context
                              ?.entity?.identifierValue ?? ""
                          }>
                          {(facts as IApiFact[])[0]?.contextRef?.context?.entity
                            ?.identifierValue ?? ""}
                        </StyledTypography>
                      )}
                    </Box>
                  </Stack>
                  {GleifValidNameMemo === true ? null : (
                    <Box
                      sx={{
                        display: "grid",
                        placeItems: "center",
                      }}>
                      <Alert
                        severity="warning"
                        sx={{
                          mt: 1,
                          mr: 2,
                        }}>
                        <Trans>
                          The value used in the fact 'Name of reporting entity
                          or other means of identification' does not match any
                          of the names registered at GLEIF
                        </Trans>
                      </Alert>
                    </Box>
                  )}
                  <FormHelperText sx={{ textAlign: "center" }}>
                    <Trans>This information has been taken from the</Trans>
                    &nbsp;
                    <Link href={gleifPublicUrl} target="_blank">
                      <Trans>Global Legal Entity Identifier Foundation.</Trans>
                    </Link>
                  </FormHelperText>
                </Box>
              )}
              {GleifData === null &&
                !GleifDataLoaded &&
                downloadedPayloadType !== "viewerFile" && (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
            </Grid>
          )}
          {(!facts || facts.length === 0) &&
            downloadedPayloadType !== "viewerFile" && (
              <Container
                sx={{
                  maxHeight: 850,
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <Alert
                  icon={false}
                  severity={"warning"}
                  sx={{ mt: 10, backgroundColor: "transparent" }}
                  color="info">
                  <Stack
                    direction={"column"}
                    justifyContent="center"
                    alignItems="center">
                    <WarningAmberIcon
                      sx={{
                        fill: "rgba(253, 118, 14, 0.45)",
                        height: "100px",
                        width: "100px",
                      }}
                    />
                    {t`Data from package could not be loaded.`}
                  </Stack>
                </Alert>
              </Container>
            )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

/* Styled Typography */
const StyledTypography = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    color: theme.palette.text.primary,
    display: "inline-block",
    flex: 1,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
